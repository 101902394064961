<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Departamente
    template(v-slot:leftmenu)
      el-tooltip(content="Adauga un nou departament" v-if="$has_right('editare-departamente')")
        el-button(type='primary' @click='show_dialog()' icon='el-icon-plus' size='small' circle)
      
    el-table( :data="Results" )
      el-table-column( label="Nume" )
        template(slot-scope="prop")
          el-tag( v-if="prop.row.Sters == '1'" type='danger') {{prop.row.Nume}}
          p(v-else) {{prop.row.Nume}}
      el-table-column( fixed="right" label="Actiuni" width="200px" )
        template( slot-scope="scope" )
          div(v-if="scope.row.Sters=='0'")
            el-tooltip( content="Modificare" v-if="$has_right('editare-departamente')")
              el-button( type="primary" size='mini' icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" )
            el-tooltip( content="Sterge" v-if="$has_right('stergere-departamente')")
              el-button( type="danger" size='mini' icon="el-icon-delete" circle @click="delete_item(scope.row)"    )
          div(v-else)
            el-tooltip( content="Pune inapoi item sters" v-if="$has_right('stergere-departamente')")
              el-button( type="success" size='mini' icon="el-icon-d-arrow-left" circle @click="undelete_item(scope.row)"    )
            el-tooltip( content="Sterge de tot din baza de date" v-if="$has_right('stergere-departamente')")
              el-button( type="danger" size='mini' icon="el-icon-delete" circle @click="fulldelete_item(scope.row)"    )
    departament-dialog( ref='dlg' @save="refresh_info()" )
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import DepartamentDialog from '../dialogs/DepartamentDialog';
import TitluPagina from '../widgets/TitluPagina';

export default {
  name: "departamente",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'departament-dialog':DepartamentDialog,
      'titlu-pagina': TitluPagina
  },
  data () {
    return { 
      Results: [],
      Filters: {},
    }
  },
  methods: {
    async get_info(){
      this.refresh_info();
    },
    async refresh_info(){
      var response = await this.post("list-departamente", { Filters: this.Filters } );
      this.Results = response.Results;
      //
      this.select_menu_item('departamente');

    },
    async delete_item( item ){
      var confirm =  await this.$confirm(`Sterg ${item.Nume} ?`, 'Warning');
      if( confirm ){
         await this.post("departamente/delete", { id: item.Id } );
         this.refresh_info();
      }
    },
    async fulldelete_item( item ){
      var confirm =  await this.$confirm(`Sterg de tot din toate arhivele  '${item.Nume}' ? SE VOR STERGE INCLUSIV TOTI ANGAJATII`, 'Warning');
      if( confirm ){
         await this.post("departamente/full_delete", { id: item.Id } );
         this.refresh_info();
      }
    },
    async undelete_item( item ){
      var confirm =  await this.$confirm(`Punem inapoi ${item.Nume} ?`, 'Warning');
      if( confirm ){
         await this.post("departamente/undelete", { id: item.Id } );
         this.refresh_info();
      }
    },

    show_dialog(id){
      this.$refs.dlg.show_me(id);
    }
  },
  mounted(){
    settings.verify_login_and_redirect(this);
    this.get_info();
  }
};
</script>

<style lang="less" scoped>
</style>
