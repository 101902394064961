<template lang="pug">
    el-dialog( title="Utilizator" :visible.sync="showPopup" class="my-dialog-class" )
        el-form( label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" )
            el-row( :gutter="15" )
                 el-col( :span="24" )
                    el-form-item( label="Nume" prop="Nume"  :required="true" )
                        el-input( v-model="selectedObject.Nume")
        span( slot="footer" class="dialog-footer" )
            el-button( @click="showPopup=false" ) Renunta
            el-button( type="primary" @click="save") Salveaza
</template>

<script>
import settings from "@/backend/LocalSettings";
import BaseComponent from '@/pages/BaseComponent';

export default {
  name: "departament-dialog",
  extends: BaseComponent,
  data () {
    return { 
      showPopup: false,
      mode: 'add',
      selectedObject: { Nume: "", IdGrupDrepturi: "" },
      rules: {
        // Nume: [ { required: true, message: "Numele este obligatoriu" } ],
      }
    }
  },
  methods: {
     show_me: async function( id ) {
        this.showPopup        = true;
        if( id == null ) 
        {
          this.mode                = "add";
          this.selectedObject.Nume = "";
        }
        else
        {
          this.mode             = "edit";
          var            result = await this.post("get-departament", { id: id } );
          this.selectedObject   = result.Item;
        }
    },
    save: async function() {
        this.$refs['my-form'].validate( async(valid) => {
          if (valid) 
          {
            await this.post("save-departament", { mode: this.mode, object: this.selectedObject } );
            this.showPopup = false;
            this.$emit("save");
          }
        });
    }
  },
  mounted: function() {
  }
};
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    
</style>